<template>
  <v-container fluid>
    <v-card>
      <div class="card-header-padding">
        <v-row>
          <v-col>
            <div>
              <h5 class="font-weight-bold text-h5 text-typo mb-0" @click="_print(selectedClassGroup)">
                {{ selectedClassGroup.STUDENT_GROUP_NAME }} ангийн чөлөө олголт
              </h5>
              <v-alert
                color="#F8BBD0"
                class="pt-4"
                text
                prominent
                icon="mdi-check-circle-outline"
              >
                <div style="color: #d81b60; font-weight: normal">
                  Захирал, менежер, нийгмийн ажилтан, эмчийн олгосон чөлөөнүүд
                  энд xарагдана.
                </div>
              </v-alert>
            </div>
          </v-col>
          <v-col lg="2" md="2" sm="12">
            <v-select
              :items="this.$store.state.calendarButez2"
              v-model="selectedSemester"
              label="Улирал"
              return-object
              item-text="session"
              item-value="session"
            >
            </v-select>
          </v-col>
        </v-row>
      </div>
    </v-card>
    <v-card>
      <v-card-text>
        <v-progress-linear
          v-if="loading"
          color="red"
          height="6"
          indeterminate
        ></v-progress-linear>
        <v-data-table
          class="mt-10"
          v-if="studentRecords"
          :headers="headerNames"
          :items="filteredReports"
          :search="search"
          hide-details
          hide-default-footer
          :items-per-page="-1"
        >
          <template slot="item" slot-scope="props">
            <tr v-if="props.item.selectedStudent">
              <td @click="_print(props.item)">{{ props.item.index }}</td>
              <td v-if="props.item" style="width: 12%">
                <span v-if="props.item.selectedStudent['LAST_NAME']">
                  {{ props.item.selectedStudent["LAST_NAME"][0] }}.{{
                    props.item.selectedStudent["FIRST_NAME"]
                  }}
                </span>
              </td>
              <td v-else>Сурагчгүй тэмдэглэгээ xийсэн байна.</td>
              <td class="blue--text">{{ props.item.STUDENT_GROUP_NAME }}</td>
              <td>  
                <small
                  v-if="props.item.excusedByManager"
                  class="red--text ml-1"
                  style="cursor: pointer"
                  @click="$swal.fire('Менемер чөлөө олгосон')"
                  >Менежер - {{ props.item.createdByName }}</small
                >
                <small
                  v-else-if="props.item.excusedByDirector"
                  class="red--text ml-1"
                  style="cursor: pointer"
                  @click="$swal.fire('Захирал чөлөө олгосон')"
                  >Захирал</small
                >
                <small
                  v-else-if="props.item.excusedBySocialWorker"
                  class="red--text ml-1"
                  style="cursor: pointer"
                  @click="$swal.fire('Нийгмийн чөлөө олгосон')"
                  >Нийгмийн ажилтан</small
                >
                <small
                  v-else-if="props.item.excusedByDoctor"
                  class="red--text ml-1"
                  style="cursor: pointer"
                  @click="$swal.fire('Эмч чөлөө олгосон')"
                  >Эмч</small
                >
                <small
                  v-else-if="props.item.excusedByTeacher"
                  class="red--text ml-1"
                  style="cursor: pointer"
                  @click="$swal.fire('АУБ чөлөө олгосон')"
                  >АУБ</small
                >
              </td>
              <td>
                <span v-if="_getExcusedDates(props.item)"
                  >{{ _getExcusedDates(props.item)[0] }}
                </span>
              </td>
              <td>
                <span v-if="_getExcusedDates(props.item)"
                  >{{ _getExcusedDates(props.item).slice(-1)[0] }}
                </span>
              </td>
              <td>
                <span v-if="props.item.children">
                  {{ props.item.children.length }}</span
                >
              </td>
              <td
                :style="
                  props.item.selectedStatus.status == -2
                    ? 'color:#2196F3'
                    : props.item.selectedStatus.status == -3
                    ? 'color: #ffa500'
                    : ''
                "
              >
                {{ props.item.selectedStatus.text }}
              </td>
              <td style="width: 20%">
                {{ _getSubstring(props.item.description, 25) }}
              </td>
              <td
                v-if="props.item.fileShareLink"
                style="cursor: pointer"
                @click="_goToLink(props.item)"
              >
                <v-icon>mdi-eye</v-icon>
              </td>
              <td v-else>-</td>
              <td style="width: 14%">
                <template>
                  <!-- <v-icon
                    small
                    class="mr-2"
                    @click.stop="_deleteStudentRecord(props.item)"
                  >
                    mdi-delete
                  </v-icon>
                  <v-icon
                    small
                    class="mr-2"
                    @click.stop="_editStudentRecord(props.item)"
                  >
                    mdi-pencil
                  </v-icon> -->
                  <v-icon
                    small
                    class="mr-2"
                    @click.stop="_detailStudentRecord(props.item)"
                  >
                    mdi-eye
                  </v-icon>
                </template>
              </td>
            </tr>
          </template>
          <v-alert slot="no-results" :value="true" color="error" icon="warning">
            Your search for "{{ search }}" found no results.
          </v-alert>
        </v-data-table>
      </v-card-text>
    </v-card>
    <v-dialog
      scrollable
      v-model="showDetailDialog"
      max-width="50%"
      v-if="showDetailDialog&&selectedRecord"
    >
      <v-card class="py-4">
        <v-card-title class="headline"> Чөлөө олголт </v-card-title>
        <v-divider></v-divider>
        <v-card-text class="pt-4">
          <v-container>
            <v-row>
              <v-col cols="12" sm="12" md="12" class="pt-0">
                <h3>
                  <span class="text-h4 black--text"
                    >{{ selectedRecord.selectedStudent["FIRST_NAME"] }},
                  </span>
                  <span class="grey--text text-h4">{{
                    selectedRecord.selectedStudent["LAST_NAME"]
                  }}</span>
                  <span class="blue--text text-h4">
                    {{ selectedRecord["STUDENT_GROUP_NAME"] }}
                  </span>
                </h3>
              </v-col>
              <v-col cols="12" sm="12" md="12" class="pt-0">
                <div
                  v-for="child in selectedRecord.children"
                  :key="child.id"
                  class="py-1"
                >
                  <span class="font-weight-bold red--text">
                    {{ child.excusedDate }}</span
                  >
                  <p class="font-weight-bold">
                    {{ child.selectedStatus.text }}
                  </p>
                  <p class="">{{ child.description }}</p>
                  <v-divider></v-divider>
                  <p class="pt-2 blue--text">
                    {{ child.fileShareLink }}
                  </p>
                  <v-divider></v-divider>
                </div>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn
            class="grey text-capitalize"
            dark
            elevation="0"
            @click="
              selectedRecord = null;
              selectedDates = null;
              showDetailDialog = !showDetailDialog;
            "
          >
            Xaax
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>
<script>
import moment from "moment";
import { mapState } from "vuex";
import { sync } from "vuex-pathify";
export default {
  components: {},
  data: () => ({
    isManager: true,
    showDetailDialog: false,
    selectedNotolgooPath: null,
    showNotolgooDialog: false,
    selectedDates: null,
    selectedClassGroup: null,
    classGroups: null,
    departments: null,
    showDateSelectDialog: false,
    statuses: [
      { text: "Чөлөөтэй", status: -2 },
      { text: "Өвчтэй", status: -3 },
      { text: "Зайнаас", status: -5 },
    ],
    studentRecords: null,
    loading: false,
    students: null,
    selectedRecord: null,
    giveExcuseDialog: false,
    selectedSemester: null,
    currentYear: null,
    currentDay: null,
    currentMonth: null,
    search: "",
    requestData: {
      description: null,

      startMinutes: null,
      startHours: null,
      startDay: null,
      startMonth: null,

      endMinutes: null,
      endHours: null,
      endDay: null,
      endMonth: null,

      requestType: null,
    },
    headerNames: [
      {
        text: "No",
        align: "end",
        value: "index",
        sortable: true,
        width: "1%",
        fixed: true,
      },
      {
        text: "Овог, нэр",
        align: "start",
        sortable: true,
        value: "firstName",
      },

      {
        text: "Бүлэг",
        align: "start",
        value: "classGroup",
      },
      {
        text: "Xэн",
        align: "start",
        sortable: true,
        value: "classGroup",
      },
      {
        text: "Эxлэx",
        align: "start",
        sortable: true,
        value: "createdAt",
      },
      {
        text: "Дуусаx",
        align: "start",
        sortable: true,
        value: "createdAt",
      },
      {
        text: "Xоног",
        align: "start",
        sortable: true,
        value: "Xоног",
      },
      {
        text: "Төлөв",
        align: "start",
        sortable: true,
        value: "status.value",
      },
      {
        text: "Тайлбар",
        align: "start",
        sortable: false,
        value: "description",
      },
      {
        text: "Нотолгоо",
        align: "start",
        sortable: true,
        value: "file",
      },
      {
        text: "Үйлдэл",
        align: "start",
        sortable: true,
        value: "actions",
      },
    ],
  }),
  computed: {
    ...sync("*"),
    ...mapState(["userProfile", "currentUser"]),
    filteredReports() {
      var list = [];
      var counter = 0;
      for (const dd of this.studentRecords) {
        var found = list.find((el) => el.randomIndex == dd.randomIndex);
        if (found) {
          found.children.push(dd);
        } else {
          counter++;
          dd.index = counter;
          dd.children = [dd];
          list.push(dd);
        }
      }
      return list;
    },
  },
  props: {
    zClassGroup: {
      type: Object,
    },
  },
  watch: {
    selectedDates(val) {
      if (val && val.length > 5 && this.userData.role == "schoolmanager")
        this.selectedDates = val.slice(0, 5);
      else if (val && val.length > 1 && this.userData.role == "teacher")
        this.selectedDates = val.slice(0, 1);
    },

    selectedSemester(val) {
      if (val) {
        this.loading = true;
        this.selectedClassGroup.ref
          .collection("attendances-" + this.$store.state.schoolStartYear)
          .where("excused", "==", true)
          .where("semester", "==", val.session)
          .orderBy("createdAt", "desc")
          .onSnapshot((docs) => {
            this.studentRecords = [];
            var counter = 0;
            docs.forEach((doc) => {
              counter++;
              let rec = doc.data();
              rec.id = doc.id;
              rec.ref = doc.ref;
              rec.index = counter;
              // console.log(rec, "rec");
              this.studentRecords.push(rec);
              //25217
            });
            this.loading = false;
          });
      }
    },
  },
  async created() {
    this.statuses = [
      { text: "Чөлөөтэй", status: -2 },
      { text: "Өвчтэй", status: -3 },
      { text: "Зайнаас", status: -5 },
    ];
    if (this.userData.role == "schoolsocialworker") {
      this.statuses = [{ text: "Чөлөөтэй", status: -2 }];
    } else if (this.userData.role == "schooldoctor") {
      this.statuses = [{ text: "Өвчтэй", status: -3 }];
    }

    this.selectedSemester = {
      session: this.$store.state.schoolCurrentSemester,
    };
    if (this.zClassGroup != null) {
      this.isManager = false;
      this.selectedClassGroup = this.zClassGroup;
    }
  },
  methods: {
    _goToLink(item) {
      window.open(item.fileShareLink, "_blank");
    },
    _getSubstring(text, limit) {
      if (text) {
        if (text.length <= limit) return text;
        else {
          return text.substring(0, limit) + "...";
        }
      }
      return null;
    },
    _getExcusedDates(record) {
      var dds = [];
      for (const dd of record.children) {
        dds.push(new Date(dd.year + "-" + dd.month + "-" + dd.day));
      }
      return dds.sort((a, b) => a - b).map((el) => el.toLocaleDateString());
    },
    _print(item) {
      console.log(item.ref.path);
    },
    _getDayFormatted2(ddd) {
      return moment(ddd.toDate()).format("YYYY/MM/DD");
    },

    _detailStudentRecord(item) {
      console.log(item);
      this.selectedRecord = Object.assign({}, item);
      this.selectedClassGroup = item.classGroup;
      this.selectedDates = [];
      for (const dd of item.children) {
        this.selectedDates.push(dd.excusedDate);
      }
      this.showDetailDialog = true;
    },
  },
};
</script>
