<template>
  <v-app>
    <v-tabs v-model="selectedTab" style="flex: 0 0 auto">
      <v-tab :class="{ 'active-tab': selectedTab === 0 }"> Чөлөө олголт</v-tab>
      <v-tab :class="{ 'active-tab': selectedTab === 1 }">
        Сурагчийн чөлөө олголт
      </v-tab>
    </v-tabs>
    <v-tabs-items v-model="selectedTab" style="background-color: white">
      <v-tab-item>
        <ClassTeacherExcuseMonitoring
          :zClassGroup="zClassGroup"
        ></ClassTeacherExcuseMonitoring>
      </v-tab-item>
      <v-tab-item>
        <ClassStudentsIrzConfirmation
          :selectedTeacher="userData"
          :zClassGroup="zClassGroup"
        ></ClassStudentsIrzConfirmation>
      </v-tab-item>
    </v-tabs-items>
  </v-app>
</template>

<script>
import { mapState } from "vuex";
import { sync } from "vuex-pathify";
import ClassStudentsIrzConfirmation from "@/sms/manager/ClassStudentsIrzConfirmation.vue";
import ClassTeacherExcuseMonitoring from "./ClassTeacherExcuseMonitoring.vue";
export default {
  components: {
    ClassTeacherExcuseMonitoring,
    ClassStudentsIrzConfirmation,
  },
  data: () => ({
    selectedTab: 0,
  }),
  props: {
    zClassGroup: {
      type: Object,
    },
  },
  computed: {
    ...sync("*"),
    ...mapState(["userProfile", "currentUser"]),
  },
  created() {},
  methods: {},
};
</script>
<style>
.cancel-btn-class {
  background-color: grey !important;
  color: black;
}
.active-tab {
  background-color: #ffc107; /* Your desired background color */
  color: #ffffff; /* Your desired text color */
}
</style>
 